<template>
  <div class="beiBox">
    <div class="main">
      <div class="beiNav">
        <h1>批量入账</h1>
        <img
          class="cha"
          @click="close"
          src="../assets/img/index/cha.png"
          alt=""
        />
      </div>

      <div class="beiMain">
        <div class="wenben">订单号:{{ entryid }}</div>
        <div class="wenben1">批量操作实收金额按应收写入</div>
        <div class="entryinput">
          <!--<el-input v-model="value" placeholder="请输入金额类型"></el-input>-->
          <el-input v-model="value1" placeholder="请输入发票号"></el-input>
        </div>
        <div class="entryinput1">
           <el-input
            v-model="remark"
            type="textarea"
            placeholder="备注信息"
        />
        </div>
        <div class="btn">
          <button class="save" @click="bao">确定</button>
          <button class="xiao" @click="close">取消</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ElMessageBox } from "element-plus";
import * as api from "../api/order";
export default {
  props: ["pientryids"],
  data() {
    return {
      entryid:'',
      value1: "",
      remark: "",
    };
  },
  created(){
    console.log(this.pientryids);
    if(this.pientryids.length == 1)
    {
      this.entryid = '#'+this.pientryids[0];
    }else{
      this.entryid = '#'+this.pientryids[0]+'等'+this.pientryids.length+'个订单';
    }
  },
  methods: {
    close() {
      this.$emit("guanpientyr");
    },
    close2() {
      this.$emit("guanpientyr2");
    },
    bao() {
       ElMessageBox.confirm("确认无误，进行批量入账吗", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            let ids = this.pientryids;
            let res = await api.settleNewAll(ids,this.value1,this.remark);
            if(res.code == 1)
            {
              this.$message.success(res.message);
              this.value1 = '';
              this.remark = '';
              this.close2();
            }else{
              this.$message.warning(res.message);
            }

          })
          .catch(() => {
            this.$message.info("取消批量入账");
        });
    },
  },
  watch: {

  },
};
</script>

<style lang="less" scoped>
.beiBox {
  float: left;
  width: 1920px;
  height: 940px;
  position: relative;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 0px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 70;
}
.main {
  width: 520px;
  height: 300px;
  background-color: #fff;
  margin: 340px auto;
  border-radius: 8px;
  box-sizing: border-box;
}
.beiNav {
  width: 100%;
  height: 60px;
  background-color: #f5f5f5;
  padding: 0 10px;
  line-height: 60px;
  box-sizing: border-box;
  border-radius: 8px 8px 0 0;
}
h1 {
  float: left;
  font-size: 20px;
  margin-left: 10px;
  font-weight: 500;
  color: #2a3346;
}
.cha {
  width: 24px;
  height: 24px;
  float: right;
  margin-top: 17px;
}

.beiMain {
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
}
.entryinput {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.entryinput1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
.entryinput1 /deep/.el-textarea__inner{
  width:458px;
  height:60px;
}
.entryinput /deep/ .el-input__inner {
  width: 220px;
}
.wenben {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  font-size: 20px;
}

.wenben1 {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  font-size: 12px;
  color:red;
}

.btn {
  margin-top: 20px;
}
.save,
.xiao {
  width: 80px;
  height: 32px;
  cursor: pointer;
  opacity: 1;
  border-radius: 4px;
}
.save {
  border: none;
  background: #2c68ff;
  color: #fff;
}
.xiao {
  background: #ffffff;
  border: 1px solid #727e96;
  margin-left: 20px;
  opacity: 1;
  border-radius: 2px;
}
.xiao:hover {
  color: #2c68ff;
  border: 1px solid #2c68ff;
}
</style>